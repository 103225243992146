<template>
    <div>      
        <form-page />            
    </div>
</template>

<script>
import FormPage from './FormPage'

export default {
    components: {   
        FormPage,
    },
}
</script>