<template>

    <div>
        <div class="text-center" v-if="loaded">
            <b-spinner variant="primary" />
        </div>
        <b-card  v-else>
            <div class="cover-page">
                <div class="countdown-message-start">      
                    <h1>Tiempo {{ timeCompleted ? 'Terminado' : 'Restante'}}</h1>
                </div>
                <br>
                <div id="countdown">
                    <div class="text-center d-flex justify-content-around">
                        <b-card 
                            v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
                            class="mr-1"
                        >
                            <div class="number_time mx-1">{{ days }}</div>      
                            <div class="text_time">Días</div> 
                        </b-card>
                        
                        <b-card 
                            v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"                            
                        >
                            <div class="number_time mx-1">{{ hours }}</div>
                            <div class="text_time">Horas</div>
                        </b-card>
                        
                        <b-card 
                            v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
                            class="mx-1"
                        >
                            <div class="number_time mx-1">{{ minutes }}</div>
                            <div class="text_time">Minutos</div>
                        </b-card>
                        
                        <b-card 
                            v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
                        >
                            <div class="number_time mx-1">{{ seconds }}</div>
                            <div class="text_time">Segundos</div>                             
                        </b-card>
                        
                    </div>                
                </div>
                <div class="countdown-message-end">
                        <br>
                        <div class="text-center col-7 mx-auto">
                            <h4>
                            De no haber sido enviado a revisión dentro del plazo, <mark class="mark-investing">se enviará automáticamente.</mark>                        
                            </h4>                          
                        </div>
                        <br><br>
                        <h6>
                            <mark class="mark-investing">Recomendación:</mark> Es importante que guarde los cambios antes que el contador llegue a '0', de lo contrario perderá los últimos cambios que haya realizado.
                        </h6>
                </div>
            </div>    
        </b-card>        
        
    </div>
    
</template>

<script>
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import useAppConfig from '@core/app-config/useAppConfig';
import router from '@/router';
import axios from 'axios'
import moment from 'moment/src/moment';
import Swal from 'sweetalert2'

Vue.use(VueSweetalert2);

export default {    
    props: {
        formData: {
            type: Object,
            required: true
        },
        ct_deadline: {
            type: Date,
            required: true
        }
    },
    data: () => ({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        loaded: true,
        timeCompleted: false,
        formId: router.currentRoute.params.id,
    }),
    mounted () {        
        const distance = this.getDistanceTime()		
	/* 	if (distance > 0) { */
            this.interval = setInterval(() => {
                this.loaded = false
				this.showCountdown()
			}, 1000)
		/* } */ 
    },
    beforeDestroy() {
		clearInterval(this.interval)
	},
    methods: {
        resetTime () {
            this.days = 0
            this.hours = 0
            this.minutes = 0
            this.seconds = 0
        },
        getDistanceTime() {
			const now = new Date().getTime()
            const end = this.ct_deadline.getTime() 
			return end - now
		},        
        formatNumber(n){
            return n.toString().padStart(2, '0');
        },
        showCountdown() {
            const distance = this.getDistanceTime()
            
            if(distance < 0){
               /*  this.loaded = false; */
                this.timeCompleted = true
                this.resetTime()
                clearInterval(this.interval);
            } else {
                this.days = Math.floor(distance / (1000 * 60 * 60 * 24)) 
                this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
                
                this.days = this.formatNumber(this.days)
                this.hours = this.formatNumber(this.hours)
                this.minutes = this.formatNumber(this.minutes)
                this.seconds = this.formatNumber(this.seconds)
                
                this.showReminder(this.days, this.hours, this.minutes, this.seconds);    
            }
        },
        showReminder(d, h, m, s){
            let no_send = false;
            
            if(d == 0 && h == 0 && m == 5 && s == 0){                
                 Swal.fire({
                    title: 'Advertencia',
                    text: '¡Solo te quedan 5 minutos para enviarlo!',
                    showConfirmButton: false,
                    timer: 2500,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'btn btn-warning',
                    },
                    buttonsStyling: false,
                })                
            }
            
            if(d == 0 && h == 0 && m == 0 && s == 0){
                no_send = true;
                this.updateState(no_send);
                               
                 Swal.fire({
                    title: 'Tiempo Terminado',
                    text: '¡Se enviará automáticamente!',
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    buttonsStyling: false,
                });         
                    
                setTimeout(()=> {     
                    this.$router.push({ name: 'provider-forms'})
                }, 3000);
            }
            
        },
        async updateState(value){            
            if(value){
                const { state_id } = this.formData
                await axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/form/${this.formId}/update/state`, { state_id, value } );                    
            }            
        }        
    },
    setup() {
        const { skin } = useAppConfig();
        return { skin }
    }
};
</script>

<style scoped>
    
    
    .cover-page {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: .5s ease-out all;
        margin-top: 1rem;
        border-radius: 5px;
        padding: 1.2rem;
    }

    #countdown {
        display: flex;
        margin: 1rem 0;
        justify-content: center;
    }    
    
    @media (min-width: 1200px) {     
        .number_time {
            font-size: 10px;
        }   
    }
    
    @media (min-width: 700px) {  
       .number_time {
            font-size: 30px;
        }   
    }
    
    .simply-section {
        background: #FFF;
        width: 130px;
        height: 120px;
        margin: 0 15px;
        display: flex;
        color: #343434;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5);
    }

    .simply-amount {
        display: block;
        font-size: 35px;
        font-weight: 700;
    }

    .simply-word {
        font-weight: 300;
        font-size: 20px;
    }

    .countdown-message-start,
    .countdown-message-end {
        color: #d0d2d6;
        font-weight: bold;
        font-size: 17px;
        text-transform: uppercase;
    }

    .mark-investing {
        background: var(--bg-primary-system);
        color: #d8a400;
        padding: 0.2em 0.55em;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>