<template>
	<div :class="extraClass">
		
			<b-button       
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
				:size="isFromPlanilla && !isFromAdmin ? 'lg' : 'md'"
				:block="isFromPlanilla ? true : false"
				:variant="isFromPlanilla && !isFromAdmin ? 'primary' : 'outline-primary'"
				@click="getListFiles()"
			>
				<feather-icon size="1x" :icon="role == 'admin' ? 'FileTextIcon':'UploadIcon'" />       
				{{ getButtonText() }} 
			</b-button>
			
			<b-modal
				ref="modal-files"
				centered
				size="xl"
				:title="code ? code.title : titleModal"
				modal-class="modal-primary"
				no-close-on-backdrop
				no-close-on-esc
			>

				<div class="contentModal mt-1">{{ contentModal }} {{ role !== 'admin' ? 'a presentar' : ''}} </div>
				<div v-html="code ? code.description : descriptionModal" class="descriptionModal my-2"></div>
				
				<template v-if="role != 'admin'">
					<hr>
						<div class="my-1">
							<vue-dropzone  
								ref="myVueDropzoneRef" 
								id="myVueDropzone"						
								@vdropzone-success="vsuccess"					
								@vdropzone-sending="vsending"
								@vdropzone-queue-complete="vqueueComplete"
								:options="dropzoneOptions"
								:headers="headers"
								:useCustomSlot="true"				
							>
								<div class="dropzone-custom-content">
									<h4 class="dropzone-custom-title"><strong>¡Arrastre o haga click aquí para cargar contenido!</strong></h4>
									<div class="subtitle">{{ this.acceptedAllFiles ? 'solo archivos PNG ó JPG ó JPEG ó PDF ó EXCEL' : 'solo archivos PDF ó EXCEL' }}</div>
								</div>
							</vue-dropzone>
						</div>
					<hr>
				</template>				

				<table class="table table-bordered text-center w-100 my-3">
					<thead>
						<tr>
							<th>#</th>
							<th colspan="2">Archivo</th>
							<th>Fecha de carga</th>
							<th>Opciones</th>
						</tr>
					</thead>
					<tbody>						
						<tr v-if="tableFiles.length == 0">
							<td colspan="5">No hay ningún archivo cargado.</td>
						</tr>
						<tr v-for="(item, index) in tableFiles" :key="index">							
							<td>{{ index + 1 }} </td>	
							<td colspan="2">{{ item.archivo }}</td>
							<td>{{ item.date_uploaded }}</td>
							<td>
								<b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.v-info
									variant="outline-info"
									class="mr-1 px-1"                                                 
									title="Descargar"
								>
									<b-link :href="fileSrc(item.path)" target="_blank">
										<feather-icon icon="DownloadIcon" style="color:#00cfe8"/>
									</b-link>
								</b-button>
																
								<b-button
									v-if="role != 'admin'"
									v-ripple.400="'rgba(234, 84, 85, 0.15)'"
									v-b-tooltip.hover.v-danger
									variant="outline-danger"								
									class="px-1"
									title="Eliminar"
									@click="deleteBtn(item.id)"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</td>
						</tr>
					</tbody>
				</table>

				<template #modal-footer="{cancel}">
					<b-button variant="outline-danger" @click="cancel()">
						Cerrar
					</b-button>
				</template>
			</b-modal>
			
			<!-- <ModalFiles :code="code" /> -->			
	</div>	
</template>

<script>
// import ModalFiles from '@core/components/modal-files/ModalFiles.vue'
import vue2Dropzone from 'vue2-dropzone'
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { BButton, BModal, VBModal, BCardText,VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import router from '@/router';
import moment from 'moment/src/moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
	props: ["code", "getQuestionFiles", "isFromPlanilla", "isFromAdmin", "acceptedAllFiles", "extraClass"],
	components: {			
		vueDropzone: vue2Dropzone,
		BButton,
		BCardText,
		BModal,			
		ToastificationContent,
		// ModalFiles,
	},
	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip,
		Ripple,
	},
	data() {
		return {
			PublicUrl: process.env.VUE_APP_URL,
			role: JSON.parse(localStorage.getItem('userData')).role,
			titleModal: "",
			descriptionModal: "",			
			contentModal: "Descripción de la información",			
			tableFiles: [],
			headers: { 'Content-Type': 'multipart/form-data' },
			dropzoneOptions: {
				url: `${process.env.VUE_APP_HOST}/api/dashboard/form/files/create`,
				paramName: 'file',
				acceptedFiles: this.acceptedAllFiles ? 'application/pdf,.xls,.xlsx,.png,.jpg,.jpeg' : 'application/pdf,.xls,.xlsx',			
			}
		}
	},
	methods: {		
		getButtonText () {
			let verb = this.role === 'admin' ? 'Ver' : 'Cargar'
			let subject = this.isFromPlanilla ? 'Boletas' : 'Archivos'
			
			let text = this.isFromAdmin ? (`${verb} ${subject}`).toUpperCase() : `${verb} ${subject}`
			return text			
		},
		fileSrc (file){
			return `${process.env.VUE_APP_FILES}/${file}`;
		},
		vsending(file, xhr, formData) {
			formData.append('form_id', router.currentRoute.params.id);
			formData.append('file_question_id', this.code.id);
			formData.append('auth', JSON.parse(localStorage.getItem('userData')).id);
		},	
		vqueueComplete(file, xhr, formData) {	
			this.getListFiles();
			if (this.getQuestionFiles){
				this.getQuestionFiles();
			}
		},
		vsuccess(file, response){		
			if(response) {
				Swal.fire({
					title: 'El contenido se ha cargado exitosamente!',
					icon: 'success',
					showConfirmButton: false,
					timer: 2100,
					customClass: {
						confirmButton: 'btn btn-success',
					},
					buttonsStyling: false,
				});
				this.$refs['myVueDropzoneRef'].removeFile(file);
			}
		},
		getListFiles(){
			this.tableFiles = [];
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/form/files?form_id=${router.currentRoute.params.id}&file_question_id=${this.code.id}`)
			.then(response => {
								
				if(response.data){
					response.data.map( item => {
						this.tableFiles.push({
							id: item.id,
							archivo: item.original_name,
							date_uploaded: moment(item.createdAt).format('DD/MM/YYYY h:mm A'),
							path: item.document_storage,
						});						
					});					
					this.$refs['modal-files'].show();
				}				
			});			
		},
		deleteBtn(id){
			
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/form/files/eliminar?fileId=${id}&form_id=${router.currentRoute.params.id}&auth=${JSON.parse(localStorage.getItem('userData')).id}`)
			.then(response => {
				
				this.getListFiles();

				if (this.getQuestionFiles){
					this.getQuestionFiles();
				}
				
				Swal.fire({
					title: response.data.message,
					icon: 'success',
					showConfirmButton: false,
					timer: 2000,
					customClass: {
						confirmButton: 'btn btn-success'
					},
					buttonsStyling: false
				});  
			})
			.catch( (err) => {
				Swal.fire({
					title: 'Error',
					text: 'Ups, hubo un error al eliminar el archivo!',
					icon: 'error',
					customClass: {
						confirmButton: 'btn btn-danger',
					},
					buttonsStyling: false,
				});
			});			
		},	
	}
  
}
</script>

<style scoped>
	.modal-header{
		text-transform: uppercase !important; 
	}

	.descriptionModal{
		font-size: .995rem;	
	}

	.contentModal{
		
		font-size: 1.2rem;	
		font-weight: bold;
	}
</style>