<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>CARGA MASIVA (EXCEL)</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

    
            <div class=" mx-2 mt-2">
                <b-link 
                    :href="tariff_id ? '/plantillas/plantilla_minera_poderosa_planilla_tarifario_de_minerias.xlsx' : '/plantillas/plantilla_minera_poderosa_planilla.xlsx'" 
                    download
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn btn-block btn-outline-success"
                >
                    <b-icon icon="file-excel" aria-hidden="true"></b-icon>
                    DESCARGAR PLANTILLA
                </b-link>
                <div style="margin-top: 8px;" class=" text-right">
                    <b-badge variant="light-warning">
                        <span class="small">Última actualización: 29/03/2024</span>
                    </b-badge>
                </div>
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Names -->
                    <validation-provider
                        #default="{ errors }"
                        name="archivo"
                        rules="required">
                        <!-- Avatar -->
                        <b-form-group
                            label="Archivo"
                            label-for="workerFile"
                        >
                            <b-form-file
                                id="workerFile"
                                v-model="userData.file"
                                :state="errors.length > 0 ? false:null"
                                @change="onFileChange"
                                accept=".xls,.xlsx"
                                placeholder="Elija un archivo o suéltelo aquí ..."
                                drop-placeholder="Suelta el archivo aquí ...."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled = "isLoading || isSubmitDisabled"
                        >
                            <b-spinner small v-if="isLoading"/>
                            {{ isLoading ? 'Cargando...' : 'Cargar' }}
                        </b-button>
                        <b-button
                            v-if="!isLoading"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cerrar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BSidebar, BForm, BFormGroup, BFormFile, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref } from '@vue/composition-api';
    import { required } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import Swal from 'sweetalert2'
    import axios from 'axios';
    import router from '@/router';

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormFile,
            BFormInvalidFeedback,
            BButton,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            form: {
                required: true
            },
            tariff_id: {
                required: true
            },
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                required,     
                //PublicUrl: process.env.VUE_APP_URL,
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();

            const isLoading = ref(false);
            const isSubmitDisabled =  ref(true);

            const blankUserData = {
                file: null,
                stateFile: null
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
                isLoading.value = false;
                isSubmitDisabled.value = true;
            };

            const onSubmit = () => {
                isLoading.value = true;
                let formData = new FormData();
                formData.append("file", userData.value.file);
                formData.append("auth", JSON.parse(localStorage.getItem('userData')).id);

                axios
                    .post(`${process.env.VUE_APP_HOST}/api/dashboard/form/${props.form}/workers/import`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data'}
                    })
                    .then( (response) => {
                        isLoading.value = false;
                        
                        if(response.data.error) {
                            resetuserData();
                            Swal.fire({
                                text: response.data.message,
                                icon: 'error',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: `btn btn-${response.data.state}`,
                                },
                                buttonsStyling: false
                            })  
                            return;
                        } 

                        Swal.fire({
                            text: response.data.message,
                            icon: response.data.state,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                        emit('refetch-data');
                        emit('update:is-add-new-user-sidebar-active', false);
                    })
                    .catch( (error) => {         
                        resetuserData();         

                        Swal.fire({
                            title: 'Error',
                            text: error.response.data.message,
                            icon: 'error',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: `btn btn-${error.response.data.state}`,
                            },
                            buttonsStyling: false
                        });
                    });
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.file = file;
                    userData.value.stateFile = Boolean(userData.value.file);
                    isSubmitDisabled.value = false;
                } else {
                    userData.value.file = null;
                    userData.value.stateFile = null;
                    isSubmitDisabled.value = true;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onFileChange,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,

                isLoading,
                isSubmitDisabled
            }
        },
    }

</script>